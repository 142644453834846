<template>
  <div class="">
    <div v-show="isShow" class="max-width">
      <div ref="observerTarget">
        <CardSingle
          v-if="sectionTemplate === 'group_card' && !isDesktop"
          :is-loading="isLoading"
          :see-more-link="getSlug"
          :image="imageCoverUrl"
          :title="punchLine"
          :template="sectionTemplate"
          :sub-title="title"
          :description="tagLine"
          @on-group-clicked="groupCardOnClicked"
        />
        <template v-else>
          <MoreToExplore
            v-if="isMoreToExplore"
            :restaurants="restaurants"
            :title="punchLine"
            :tag-line="tagLine"
            :see-more-link="getSlug"
            :slide-per-view="slidePerView"
            :is-loading="isLoading"
            :side-image="sideImage"
          />
          <HomeSliderSection
            v-else
            :restaurants="restaurants"
            :slide-per-view="slidePerView"
            :is-loading="isLoading"
            :side-image="sideImage"
            :tag-line="tagLine"
            :see-more-link="getSlug"
            :title="punchLine"
            :simple="false"
            :show-see-more="getSlug.length > 0"
            :is-more-to-explore="isMoreToExplore"
            @on-card-clicked="onCardClicked"
            @on-see-more-clicked="onSeeMoreClicked"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useIntersectionObserver } from "@vueuse/core";
import {
  onMounted,
  ref,
  type Ref,
  toRefs,
  watch,
  defineAsyncComponent,
  computed,
} from "vue";
import { storeToRefs } from "pinia";
import type {
  RestaurantCardProps,
  RestaurantCardSliderProps,
} from "~/types/Restaurant";
import {
  getHomeSection,
  isBranchesTags,
  isRestaurantTags,
} from "~/services/common/homeSection";
import alert from "~/lib/alert";
import { toSlug } from "~/helpers/string";
import { restaurantCardMapper } from "~/services/restaurant";
import useClientTypeStore from "~/stores/clientType";
import type { HomepageModelEvent, SectionTemplate } from "~/types/Home";

const props = defineProps({
  apiOrder: {
    type: Number,
    default: 0,
  },
  homeSectionOrder: {
    type: Number,
    default: 0,
  },
  slidePerView: {
    type: [Number, String],
    required: true,
  },
  sideImage: {
    type: Boolean,
    default: false,
  },
  isMoreToExplore: {
    type: Boolean,
    default: false,
  },
  sectionTemplate: {
    type: String,
    default: "" as SectionTemplate,
  },
  endPointApi: {
    type: String,
    default: "",
  },
  imageCoverUrl: {
    type: String,
    default: "",
  },
  punchLine: {
    type: String,
    default: "",
  },
  title: {
    type: String,
    default: "",
  },
});

const emits = defineEmits<{
  (e: "on-group-clicked", data: HomepageModelEvent): void;
  (
    e: "on-click-restaurant",
    data: { card: RestaurantCardProps; section: HomepageModelEvent }
  ): void;
  (
    e: "on-click-featured-restaurant",
    data: { card: RestaurantCardProps; section: HomepageModelEvent }
  ): void;
  (e: "on-see-more-clicked", data: string): void;
}>();
const clientTypeStore = useClientTypeStore();
const { isDesktop } = storeToRefs(clientTypeStore);
const { apiOrder, sectionTemplate, endPointApi, punchLine, title } =
  toRefs(props);
const observerTarget = ref(null);
const isVisible = ref(false);
const isDataLoaded = ref(false);
const isLoading = ref(true);
const isShow = ref(true);
const slug = ref("");
const tagLine = ref("");
const restaurants: Ref<RestaurantCardSliderProps["restaurants"]> = ref([]);

const HomeSliderSection = defineAsyncComponent(
  () => import("./HomeSliderSection.vue")
);

const CardSingle = defineAsyncComponent(
  () => import("~/components/restaurant/RestaurantCard/Type/CardSingle.vue")
);
const MoreToExplore = defineAsyncComponent(
  () => import("~/section/more_to_explore/MoreToExplore.vue")
);

const { stop } = useIntersectionObserver(
  observerTarget,
  ([{ isIntersecting }], observerElement) => {
    isVisible.value = isIntersecting;
  }
);

const getSlug = computed(() => {
  return slug.value ? toSlug(slug.value) : "";
});

function onCardClicked({
  card,
  position,
}: {
  card: RestaurantCardProps;
  position: number | string;
}) {
  const restaurantData = {
    card,
    section: {
      sectionName: title.value,
      sectionTemplate: sectionTemplate.value,
      position,
    },
  };
  if (card.isFeatured) {
    emits("on-click-featured-restaurant", restaurantData);
  } else {
    emits("on-click-restaurant", restaurantData);
  }
}

function onSeeMoreClicked(link: string) {
  emits("on-see-more-clicked", link);
}

function groupCardOnClicked(data: HomepageModelEvent) {
  emits("on-group-clicked", data);
}

async function fetchData() {
  if (isDataLoaded.value) {
    return;
  }
  isLoading.value = true;
  const { data, isSuccess, message } = await getHomeSection({
    order: apiOrder.value,
    endPointApi: endPointApi.value,
  });
  if (!isSuccess || !data) {
    alert.error(message);
    return;
  }
  if (data) {
    restaurants.value = [];
    slug.value = data.slug;
    tagLine.value = data.tagLine;
    const restaurantsData = data.data;
    const restaurantsMapper = restaurantsData.map(async (item) => {
      if (!isRestaurantTags(item) && !isBranchesTags(item)) {
        return await restaurantCardMapper({ restaurant: item });
      }
    });
    for await (const restaurant of restaurantsMapper) {
      if (restaurant) {
        restaurants.value.push(restaurant);
      }
    }
    isShow.value = restaurants.value.length > 0;
    isLoading.value = false;
    isDataLoaded.value = true;
    stop();
  }
}

onMounted(() => {
  watch(isVisible, (newVal) => {
    if (newVal === true) {
      fetchData();
    }
  });
  watch(endPointApi, () => {
    fetchData();
  });
});
</script>
<script lang="ts">
export default {
  name: "HomeRestaurantSlider",
};
</script>
